import API from "@utils/request";

const WORK_ORDER_APPLY = '/index/';

/**
 * 工单台账
 * @param {object} params 
 * @returns 
 */
export async function workOrderApplyStandingBook(params) {
    try {
        return await API.post(WORK_ORDER_APPLY + 'workOrderApplyStandingBook', params)
    } catch (error) {
        return error;
    }
}

/**
 * 工单台账-导出
 * @param {object} params 
 * @returns 
 */
export async function workOrderApplyStandingExport(params) {
    try {
        return await API.post(WORK_ORDER_APPLY + 'workOrderApplyStandingBookExport', params, {
            responseType: "blob",
        });
    } catch (error) {
        return error;
    }
}

/**
 * 详情
 * @param {int|string} id 
 * @returns 
 */
export async function workOrderApplyDetail(id) {
    try {
        return await API.post(WORK_ORDER_APPLY + "workOrderApplyDetail", {
            id: id
        });
    } catch (error) {
        return error;
    }
}

/**
 * 工单报表
 * @param {object} params 
 * @returns 
 */
export async function workOrderApplyReport(params) {
    try {
        return await API.post(WORK_ORDER_APPLY + 'workOrderApplyReport', params)
    } catch (error) {
        return error;
    }
}

/**
 * 工单报表-导出
 * @param {object} params 
 * @returns 
 */
 export async function workOrderApplyReportExport(params) {
    try {
        return await API.post(WORK_ORDER_APPLY + 'workOrderApplyReportExport', params, {
            responseType: "blob",
        });
    } catch (error) {
        return error;
    }
}