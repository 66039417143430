import API from "@utils/request";

const USER = '/index/';

/**
 * 员工-列表
 * @param {object} params 
 * @returns 
 */
export async function getStaffList(params) {
    try {
        return await API.get(USER + 'staffList', {
            params,
            headers: { isLoading: true }
        })
    } catch (error) {
        return error;
    }
}
/**
 * 员工-列表-不分页
 * @param {object} params 
 * @returns 
 */
export async function getStaffListAll(params) {
    try {
        return await API.get(USER + 'staffListAll', {
            params,
            headers: { isLoading: true }
        })
    } catch (error) {
        return error;
    }
}
/**
 * 员工导出-模板下载
 * @param {object} params 
 * @returns 
 */
 export async function staffTemplateDown(params) {
    try {
        return await API.post(USER + 'staffTemplateDown', params, {
            responseType: "blob",
        });
    } catch (error) {
        return error;
    }
}
/**
 * 编辑
 * @param {object|int} params 参数
 * @param {string} method 请求方式：get=回显；post=提交保存
 * @returns 
 */
export async function staffUpdate(params, method = 'get') {
    try {
        if (method == 'get') {
            return await API.get(USER + 'staffUpdate', {
                params: params
            });
        } else {
            return await API.post(USER + 'staffUpdate', params);
        }
    } catch (error) {
        return error;
    }
}
/**
 * 手机号变更
 * @param {object} params 
 * @returns 
 */
export async function staffMobileUpdate(params) {
    try {
        return await API.post(USER + "staffMobileUpdate", params);
    } catch (error) {
        return error;
    }
}

/**
 * 新增
 * @param {object} params 
 * @returns 
 */
export async function staffAdd(params) {
    try {
        return await API.post(USER + 'staffAdd', params);
    } catch (error) {
        return error;
    }
}

/**
 * 删除
 * @param {object} params 
 * @returns 
 */
export async function staffDelete(id) {
    try {
        return await API.post(USER + 'staffDelete', {
            id:id
        });
    } catch (error) {
        return error;
    }
}


/**
 * 业主列表
 * @param {object} params 
 * @returns 
 */
export async function getOwnerList(params) {
    try {
        return await API.post(USER + 'ownerList', params)
    } catch (error) {
        return error;
    }

}
/**
 * 编辑
 * @param {object|int} params 参数
 * @param {string} method 请求方式：get=回显；post=提交保存
 * @returns 
 */
export async function ownerUpdate(params, method = 'get') {
    try {
        if (method == 'get') {
            return await API.get(USER + 'ownerUpdate', {
                params: params
            });
        } else {
            return await API.post(USER + 'ownerUpdate', params);
        }
    } catch (error) {
        return error;
    }
}

/**
 * 手机号变更
 * @param {object} params 
 * @returns 
 */
export async function ownerMobileUpdate(params) {
    try {
        return await API.post(USER + "ownerMobileUpdate", params);
    } catch (error) {
        return error;
    }
}
/**
 * 业主状态变更
 * @param {object} params 
 * @returns 
 */
export async function ownerStatusUpdate(params) {
    try {
        return await API.post(USER + 'ownerStatusUpdate', params);
    } catch (error) {
        return error;
    }
}
/**
 * 业主审核
 * @param {object} params 
 * @returns 
 */
export async function ownerExamine(params) {
    try {
        return await API.post(USER + 'ownerExamine', params);
    } catch (error) {
        return error;
    }
}

/**
 * 删除
 * @param {object} params 
 * @returns 
 */
export async function ownerDelete(id) {
    try {
        return await API.post(USER + 'ownerDelete', {
            id:id
        });
    } catch (error) {
        return error;
    }
}