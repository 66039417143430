import API from "@utils/request";

const PROJECT = '/index/';

/**
 * 项目列表
 * @param {object} params 
 * @returns 
 */
export async function getProjectList(params) {
    try {
        return await API.post(PROJECT + 'projectList', params);
    } catch (error) {
        return error;
    }
}

/**
 * 创建项目
 * @param {object} params 
 * @returns 
 */
export async function $createProject(params) {
    try {
        return await API.post(`${PROJECT}projectAdd`, params);
    } catch (error) {
        return error;
    }
}

/**
 * 项目详情
 * @param {int} id 
 * @returns 
 */
export async function $projectDetail(id) {
    try {
        return await API.get(`${PROJECT}projectUpdate?id=${id}`);
    } catch (error) {
        return error;
    }
}

/**
 * 项目编辑保存
 * @param {int} id 
 * @returns 
 */
export async function $projectUpdate(params) {
    try {
        return await API.post(`${PROJECT}projectUpdate`, params);
    } catch (error) {
        return error;
    }
}

/**
 * 项目删除
 * @param {int} id 
 * @returns 
 */
export async function $projectDelete(id) {
    try {
        return await API.post(`${PROJECT}projectDelete`, {
            id
        });
    } catch (error) {
        return error;
    }
}