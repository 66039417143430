import API from "@utils/request";

const FRONT_ROLE = '/index/';

/**
 * 前台角色列表
 * @param {object} params 
 * @returns 
 */
export async function getFrontRoleList(params) {
    try {
        return await API.post(FRONT_ROLE + 'frontRoleList', params)
    } catch (error) {
        return error;
    }
}

/**
 * 前台角色新增
 * @param {object} params 
 * @returns 
 */
export async function frontRoleAdd(params) {
    try {
        return await API.post(FRONT_ROLE + "frontRoleAdd", params);
    } catch (error) {
        return error;
    }
}

/**
 * 前台角色修改
 * @param {object} params 
 * @param {string} type 
 * @returns 
 */
export async function frontRoleUpdate(params, type = "get") {
    try {
        if (type == 'get') {
            return await API.get(FRONT_ROLE + "frontRoleUpdate", { params });
        } else {
            return await API.post(FRONT_ROLE + "frontRoleUpdate", params);
        }
    } catch (error) {
        return error;
    }
}

/**
 * 前台角色删除
 * @param {int|string} id 
 * @returns 
 */
export async function frontRoleDelete(id) {
    try {
        return await API.post(FRONT_ROLE + "frontRoleDelete", {
            id:id
        });
    } catch (error) {
        return error;
    }
}