import API from "@utils/request";

const WORK_ORDER = '/index/';

/**
 * 工单（类型）列表
 * @returns 
 */
export async function getWorkOrderList() {
    try {
        return await API.post(WORK_ORDER + "workOrderList");
    } catch (error) {
        return error;
    }
}